
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'XBox',
})
export default class extends Vue {
  /** 标题 */
  @Prop({ default: '' }) title!: String
  /** 更多 */
  @Prop({ default: '' }) moreText!: String

  /** 点击更多 */
  onClickMore() {
    this.$emit('clickMore')
  }
}
